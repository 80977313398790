<template>
    <div id="mail-render" class="mail-render--container">
        <iframe ref="mail-wrapper" class="mail-render--wrapper">

        </iframe>
    </div>
</template>

<script>
import eventHub from '../../utils/eventHub'

export default {
  name: 'mail-render',
  props: [],
  emits: [],
  components: {},
  data () {
    return {
        mailWrapper: null
    }
  },
  methods: {
      async requestContent (token) {
        return await this.axios.get(process.env.VUE_APP_API + `/mail/render/${token}`, {
            headers: {}
        }).then((response) => {
            if (response?.status === 200) {
                const { data } = response
                return { ok: true, data: data }
            }
        }).catch((error) => {
            console.log('ERROR::   ', error)
            console.log(token)
            return { ok: false, error }
        })
      },
      async processRoute () {
        const { params } = this.$route
        const { token } = params || {}
        if (token) {
            const result = await this.requestContent(token)
            eventHub.$emit('show-components', false)
            if (result.ok) {
                const wrapper = this.$refs['mail-wrapper'].contentWindow.document
                wrapper.open()
                wrapper.write(result.data)
                wrapper.close()
            }
        }
      }
  },
  async created () {
      const header = document.getElementById('sf-header')
      if (header) {
          header.classList.add('s-header--hidden')
      }
      await this.processRoute()
  },
  mounted () {

  },
  unmounted () {

  }
}
</script>

<style scoped lang="scss">
    .mail-render {

        &--container {
            width: 100%;
            height: 100vh;
        }
        &--wrapper {
            width: 100%;
            height: inherit;
            border: 0;

            html {
                body {
                    margin: 0;
                }
            }
        }
    }
</style>
